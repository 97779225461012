<template>
  <div class="chart">
    <div :id="id" :style="{width: '100%', height: '640px'}"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import Title from "./Title"
export default {
  name: 'BarChart',
  components: {
    Title
  },
  props: {
    id: {
      type: String
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: '',
    }
  },
  methods: {
    initChart(initChartData) {
      const { data, title, ...commonOption } = initChartData
      this.title = title
      const getchart = echarts.init(document.getElementById(this.id))
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          bottom: '0%'
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          inverse: true,
          type: 'category',
          data: data.yData
        },
        series: [
          {
            name: '律所',
            type: 'bar',
            data: data.xData,
            barWidth: 40
          },
        ],
        grid: {
          left: '20%',
          right: '20%',
          bottom: '5%',
          containLabel: true
        },
        ...commonOption,
      }
      getchart.setOption(option)
      // 随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize()
      })
    },
  },
}
</script>
<style scoped lang="less">
  @import '~@/styles/common.less';
  .chart {
    .uni-chart-margin;
  }
</style>
