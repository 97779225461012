<template>
  <div class="basis">
    <Title :title="legalBasisData.title" />
    <div v-for="(item, index) of legalBasisData.data" :key="index" class="content">
      <div class="item">{{ item.legal_basis }}</div>
      <div class="item">{{ item.count }}</div>
    </div>
  </div>
</template>

<script>
import Title from "./Title"
export default {
  name: 'LegalBasis',
  components: {
    Title
  },
  props: {
    legalBasisData: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
<style scoped lang="less">
  @import '~@/styles/common.less';
  .basis {
    .uni-chart-margin;
    .content {
      display: flex;
      align-items: center;
      margin-top: 20px;
      .item {
        margin: 10px 5px 10px 0;
      }
    }

  }
</style>
