<template>
  <div class="chart">
    <Title :title="title" />
    <div id="polarBarChart" :style="{width: '100%', height: '320px'}"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import Title from "./Title"
export default {
  name: 'PolarBarChart',
  components: {
    Title
  },
  props: {
  },
  data() {
    return {
      title: '',
    }
  },
  methods: {
    initChart(initChartData) {
      const { data, title, ...commonOption } = initChartData
      this.title = title
      const getchart = echarts.init(document.getElementById('polarBarChart'))
      const option = {
        polar: {
          radius: [20, '90%'],
          // z: -1
        },
        angleAxis: {
          show: false,
          max: 100,
          startAngle: 90,
        },
        radiusAxis: {
          type: 'category',
          data: data.xData,
          axisLabel: {
            interval: 0
          },
          z: 10
        },
        tooltip: {},
        series: {
          type: 'bar',
          data: data.yData,
          coordinateSystem: 'polar',
          colorBy: 'data'
        },
        ...commonOption
      }
      getchart.setOption(option)
      // 随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize()
      })
    },
  },
}
</script>
<style scoped lang="less">
  @import '~@/styles/common.less';
  .chart {
    .uni-chart-margin
  }
</style>
