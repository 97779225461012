<template>
  <div class="chart">
    <Title :title="title" />
    <div id="pieChart" :style="{width: '100%', height: '320px'}"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import Title from "./Title"
export default {
  name: 'PieChart',
  components: {
    Title
  },
  props: {
  },
  data() {
    return {
      title: '',
    }
  },
  methods: {
    initChart(initChartData) {
      const { data, title, sum, ...commonOption } = initChartData
      this.title = title
      const getchart = echarts.init(document.getElementById('pieChart'))
      const option = {
        title: {
          text: '总',
          subtext: sum,
          subtextStyle: {
            fontSize: '20',
            fontWeight: 'bold'
          },
          x: 'center',
          y: 'center',
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)'
        },
        legend: {
          bottom: '0%',
          left: 'center'
        },
        series: [
          {
            type: 'pie',
            radius: ['55%', '75%'],
            avoidLabelOverlap: false,
            label: {
              formatter: '{d}%',
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              length: 30
            },
            data,
          }
        ],
        ...commonOption
      }
      getchart.setOption(option)
      // 随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize()
      })
    },
  },
}
</script>
<style scoped lang="less">
  @import '~@/styles/common.less';
  .chart {
    .uni-chart-margin
  }
</style>
