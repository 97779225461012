<template>
  <div class="commonTitle">{{ title }}</div>
</template>

<script>
export default {
  name: 'Title',
  props: {
    title: {
      type: String
    }
  },
}
</script>
<style scoped lang="less">
  .commonTitle {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
</style>
