<template>
  <div class="chart">
    <Title :title="title" />
    <div id="lineChart" :style="{width: '100%', height: '320px'}"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import Title from './Title'
export default {
  name: 'LineChart',
  components: {
    Title
  },
  props: {
  },
  data() {
    return {
      title: ''
    }
  },
  methods: {
    initChart(initChartData) {
      const { data, title, ...commonOption } = initChartData
      this.title = title
      const getchart = echarts.init(document.getElementById('lineChart'))
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: '{b} <br/>占比: {c}'
        },
        legend: {
          bottom: '0%'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data.xData,
          axisLabel: {
            interval: 0,
            rotate: -45
          },
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            type: 'line',
            stack: '总量',
            data: data.yData
          },
        ],
        ...commonOption
      }
      getchart.setOption(option)
      // 随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize()
      })
    },
  },
}
</script>
<style scoped lang="less">
  @import '~@/styles/common.less';
  .chart {
    .uni-chart-margin
  }
</style>
