<template>
  <div class="lawyerDetails">
    <Breadcrumb :data="breData" />
    <div class="content">
      <div class="title">
        {{ $route.query.caseReason + '案例分析' }}
      </div>
      <LineChart ref="lineChart" />
      <PieChart ref="pieChart" />
      <LegalBasis :legal-basis-data="legalBasisData" />
      <PolarBarChart ref="polarBarChart" />
      <div class="barChartContainer">
        <Title :title="'案例受理最多律所、律师'" />
        <div class="barChartContent">
          <BarChart :id="'barChart'" ref="barChart" :style="{width: '45%'}" />
          <BarChart :id="'barChart1'" ref="barChart1" :show-title="false" :style="{width: '45%',}" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import LineChart from './components/LineChart.vue'
import PieChart from './components/PieChart.vue'
import LegalBasis from './components/LegalBasis.vue'
import PolarBarChart from './components/PolarBarChart.vue'
import BarChart from './components/BarChart.vue'
import { mapGetters, mapActions } from 'vuex'
import Title from "./components/Title"
const commonOption = {
  grid: { // 调整图表上下左右位置
    // top: '1%',
    left: '5%',
    right: '5%',
    bottom: '5%',
    containLabel: true
  },
}
export default {
  name: 'Analysis',
  components: {
    Breadcrumb,
    LineChart,
    PieChart,
    LegalBasis,
    PolarBarChart,
    BarChart,
    Title
  },
  data() {
    return {
      breData: [
        {
          name: '法律查询',
          path: '/legal/query?type=CASE',
        },
        {
          name: '司法案例',
          path: '/legal/query?type=CASE',
        },
        {
          name: '案例分析',
        },
      ],
      legalBasisData: {}
    }
  },
  computed: {
    ...mapGetters(['analysisData']),
  },
  created() {
    this.getData().then(() => {
      console.log('analysisData', this.analysisData)
      this.initChart()
    })
  },
  mounted() {
  },
  methods: {
    ...mapActions(['getAnalysisData']),
    handleBarTypeData(option) {
      const { data, xDataName, yDataName } = option
      const result = {
        xData: [],
        yData: [],
      }
      for (let i = 0; i < data.length; i++) {
        const datum = data[i]
        result.xData.push(datum[xDataName])
        result.yData.push(datum[yDataName])
      }
      return result
    },

    initChart() {
      this.initLineChart()
      this.initPieChart()
      this.initLegalBasisData()
      this.initPolarBarChart()
      this.initBarChart()
    },
    initLineChart() {
      let { caseAnalysis } = this.analysisData
      caseAnalysis = this.handleBarTypeData({
        data: caseAnalysis,
        xDataName: 'province',
        yDataName: 'rate',
      })
      const initChartData = {
        title: '案例全国发生趋势',
        data: {
          xData: caseAnalysis.xData,
          yData: caseAnalysis.yData,
        },
        ...commonOption
      }
      this.$refs.lineChart.initChart(initChartData)
    },
    initPieChart() {
      const { outcomeRate } = this.analysisData
      const initChartData = {
        title: '案例胜负率',
        sum: outcomeRate.sum,
        data: [
          { value: outcomeRate.原告, name: '原告' },
          { value: outcomeRate.被告, name: '被告' },
        ],
        ...commonOption
      }
      this.$refs.pieChart.initChart(initChartData)
    },
    handlePolarBarChartData() {
      const { lossRate } = this.analysisData
      let sortLossRate = []
      for (const key in lossRate) {
        if (key !== 'id') {
          sortLossRate.push({
            key,
            val: lossRate[key]
          })
        }
      }
      sortLossRate = sortLossRate.sort((a, b) => a.val - b.val)
      const result = {
        xData: [],
        yData: []
      }
      for (const item of sortLossRate) {
        const { key, val } = item
        if (key !== 'id') {
          result.xData.push(key)
          result.yData.push(val)
        }
      }
      return result
    },
    initPolarBarChart() {
      const { xData, yData } = this.handlePolarBarChartData()
      const initChartData = {
        title: '案例损失率',
        data: {
          xData,
          yData,
        },
        ...commonOption
      }
      this.$refs.polarBarChart.initChart(initChartData)
    },
    handleBarChartData() {
      let { lawOfficeCount, lawyerCount } = this.analysisData
      lawOfficeCount = this.handleBarTypeData({
        data: lawOfficeCount,
        xDataName: 'count',
        yDataName: 'lawers_office',
      })
      lawyerCount = this.handleBarTypeData({
        data: lawyerCount,
        xDataName: 'count',
        yDataName: 'lawyer',
      })
      return {
        lawOfficeCount,
        lawyerCount,
      }
    },
    initBarChart() {
      const { lawOfficeCount, lawyerCount } = this.handleBarChartData()
      const initChartData = {
        title: '案例受理最多律所、律师',
        data: {
          xData: lawOfficeCount.xData,
          yData: lawOfficeCount.yData,
        },
        yAxis: {
          inverse: true,
          type: 'category',
          data: lawOfficeCount.yData,
          axisLabel: {
            rotate: 45
          }
        },
      }
      const initChartData1 = {
        title: '案例受理最多律所、律师',
        data: {
          xData: lawyerCount.xData,
          yData: lawyerCount.yData,
        },
        series: [
          {
            name: '律师',
            type: 'bar',
            data: lawyerCount.xData,
            barWidth: 40
          },
        ],
      }

      this.$refs.barChart.initChart(initChartData)
      this.$refs.barChart1.initChart(initChartData1)
    },
    initLegalBasisData() {
      const { legalBasis } = this.analysisData
      this.legalBasisData = {
        title: '法律依据',
        data: legalBasis
      }
    },
    async getData() {
      const caseReason = this.$route.query.caseReason
      await this.getAnalysisData({
        caseReason,
      })
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.lawyerDetails {
  width: @uni-width;
  margin: 0 auto;
  .content {
    margin-bottom: 20px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
    .title {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 80px;
      padding: 20px 20px;
      font-size: 26px;
      font-weight: 500;
      line-height: 30px;
      color: #333333;
      /*border-bottom: 1px solid #eee;*/
    }
    .barChartContainer {
      display: flex;
      flex-direction: column;
      .barChartContent {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
